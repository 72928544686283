import './App.css';
import { AiOutlineClose } from "react-icons/ai";
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Button, Loading } from '@nextui-org/react';
import { message, Result } from "antd";
import { SmileOutlined } from '@ant-design/icons';

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const [showNotification, setShowNotification] = useState(true);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  useEffect(() => {
    if (showSuccessScreen) {
      setShowNotification(false);
    }
  }, [showSuccessScreen]);
  //check if localstorage has betaRegistration key and set setShowSuccessScreen to true
  useEffect(() => {
    if (localStorage.getItem("betaRegistration")) {
      setShowSuccessScreen(true);
    }
  }, []);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    //send fetch post request to https://beta.webxspark.com/science/ in urlformencode format
    const formData = new URLSearchParams();
    formData.append('name', data.get('name'));
    formData.append('email', data.get('email'));
    formData.append('phone', data.get('phone'));
    formData.append('register', true);
    formData.append('booksRead', data.get('booksRead'));
    formData.append('favAuthors', data.get('favAuthors'));
    formData.append('comments', data.get('comments'));
    //validating name and email only
    if (!data.get('name') || !data.get('email')) {
      messageApi.error('Please fill all the required fields.');
      return;
    }
    setBtnDisabled(true);
    fetch('https://beta.webxspark.com/science/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData
    })
      .then(response => response.json())
      .then(res => {
        setBtnDisabled(false);
        console.log(res);
        if (res.status === 200) {
          messageApi.success(res.message)
          e.target.reset();
          localStorage.setItem("betaRegistration", JSON.stringify({ status: "done", name: data.get('name') }));
          setShowSuccessScreen(true);
        } else {
          messageApi.error(res.error);
        }
      }).catch(err => {
        setBtnDisabled(false);
        console.log(err);
        messageApi.error('Something went wrong. Please try again later.');
      }
      );
  }
  return (
    <div className="h-screen">
      <div className='flex justify-center py-10'>
        <div className='bg-white px-10 rounded-lg form-input'>
          {showSuccessScreen ? (<>
            <div className='mt-10'>
              <Result
                icon={<SmileOutlined />}
                title={`Thanks for registering ${JSON.parse(localStorage.betaRegistration).name}!`}
                extra={(<div className='sticky bottom-0'>
                  <div className='w-full pt-12 flex items-center h-full justify-center md:gap-4 gap-1'>
                    <div className='md:text-4xl hidden md:block'>🎉</div>
                    <div className='md:font-bold font-semibold text-base text-left'>Try the new Webxspark AI tool - RewordMe</div>
                    <div className='pl-4'>
                      <a href='https://ai.webxspark.com/reword-me' target={'_blank'} rel="noopener noreferrer dofollow">
                        <Button flat color="secondary" auto>
                          Open &nbsp;<FaExternalLinkAlt className='text-sm' />
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>)}
              />
            </div>
          </>) : <>
            <div className='text-center font-semibold text-2xl my-8'>Webxspark Beta</div>
            <form className='my-10' onSubmit={handleFormSubmit}>
              <div className='flex flex-col sm:flex-row gap-5'>
                <div className='flex flex-col w-full'>
                  <label>What's your name? <span className='text-red-500 font-semibold text-2xl'>*</span></label>
                  <input type={'text'} name="name" className='mt-2 mb-0 sm:mb-8 w-full focus:outline-none py-2 px-4 rounded-lg form-input bg-white ' placeholder='Name' />
                </div>
                <div className='flex flex-col w-full'>
                  <label>What's your email address? <span className='text-red-500 font-semibold text-2xl'>*</span></label>
                  <input type={'email'} name="email" className='mt-2 mb-8 w-full focus:outline-none py-2 px-4 rounded-lg form-input bg-white ' placeholder='Email Address' />
                </div>
              </div>
              <div className='flex flex-col'>
                <label>Phone number</label>
                <input type={'tel'} name="phone" className='mt-2 mb-8 w-full focus:outline-none py-2 px-4 rounded-lg form-input bg-white ' placeholder='Phone' />
                <label>How many books have you read? (Approx.)</label>
                <input type={'number'} name="booksRead" className='mt-2 mb-8 w-full focus:outline-none py-2 px-4 rounded-lg form-input bg-white ' placeholder='Answer' />
              </div>
              <div>
                <label className=''>List any 2 of your favourite books (if possible)</label>
                <textarea rows={4} name="favAuthors" className='mt-2 mb-8 w-full focus:outline-none py-4 px-4 rounded-lg form-input bg-white resize-none' placeholder='Answer' />
              </div>
              <div>
                <label>Tell us what you think about <b>Webxspark</b>.</label>
                <textarea rows={4} name="comments" className='mt-2 mb-8 w-full focus:outline-none py-4 px-4 rounded-lg form-input bg-white resize-none' placeholder='Answer' />
              </div>
              <div className='flex justify-end'>
                <Button disabled={btnDisabled} shadow color="secondary" type='submit' auto size={'lg'}>
                  {btnDisabled ? <Loading type="points-opacity" color="currentColor" size="sm" /> : 'Submit'}
                </Button>
              </div>
            </form>
          </>}
        </div>
      </div>
      {showNotification ? (<>
        <div className='md:h-16 h-20 w-screen bg-[#F7F7F8] sticky bottom-0 flex justify-center'>
          <div className='w-4/5 flex items-center h-full justify-center md:gap-4 gap-1'>
            <div className='md:text-4xl text-xl'>🎉</div>
            <div className='md:font-bold font-semibold md:text-lg text-sm'>Try the new Webxspark AI tool - RewordMe</div>
            <div className='pl-4'>
              <a href='https://ai.webxspark.com/reword-me' target={'_blank'} rel="noopener noreferrer dofollow">
                <Button flat color="secondary" auto>
                  Open &nbsp;<FaExternalLinkAlt className='text-sm' />
                </Button>
              </a>
            </div>
          </div>
          <div className='h-full flex items-center absolute bottom-0 md:right-10 right-3 cursor-pointer' onClick={() => { setShowNotification(false) }}>
            <AiOutlineClose className='font-bold text-xl' />
          </div>
        </div>
      </>) : <></>}
      {contextHolder}
    </div>
  );
}

export default App;
